export const videoIdsMusicVideos = [
    "_DkP3bW7r5I", // Kam Prada - Used to Be
    "YSTTBv78UEs", // Kam Prada - Made 4 This
    "jbbYJ-7wwWg", // Kam Prada - Sorry Not Sorry
    "zqD17zy5gNc", // inam. - Lemonade (Official Music Video)
    "IpGaHH7iyVM", // VLONE - BETTER THAN YOUR EX
    "pyyoXhi4ja4", // Spliffyy | First Day Out
];

export const videoIdsTrailers = [
    "YmYI-IHIW18", // KAYAM - PARALYZED TRAILER
    "6Ty5Kw_34Zk", //  Inam - Pulse Trailer
];

export const videoIdsPromos = [
    "4J-arc8KTos", // Twotowers x Aftergrad YYC Party Promo Wisewood
    "dGy-t7PEbnc", // Twotowers x Aftergrad YYC Party Promo Francis
];

export const videoIdsLiveEvents = [
    "gNw8LNSEYns", // Bryce Vine Live At National Saloon
    "HLy2zn-tumM", // Kam Prada Concert 08/27/2022 [Recap Video]
    "USSZlpBdmnc", // HIGH PERFORMANCE RODEO 2023 WRAP UP VIDEO
    "_1ykhIGno2A", // Kayam Live At National Saloon
    "G_Sr8YI7eTc", // Kam Prada Cowboys Music Festival 07/13/2022 [Recap Video]
    "e6_W9V-ZGsw", // 10 MINUTE PLAY FESTIVAL WRAP-UP VIDEO
];

export const videoIdsWeddings = [
    "bKj4GQiB9vY", // Anna & Omar - Los Angeles - 2022
];

export const videoIdsVisualizers = [
    "IUpYTBBj1yM", // Kam Prada - Laneswitch (feat. Pressed) [Visualizer]
    "Vwfb3-6x5Tk", // Kam Prada - Bora Bora [Visualizer]
    "HwUpv6AbUv8", // Kam Prada - Closure [Visualizer]
    "uCnlDUIZLKk", // Kam Prada - Hills (feat. Rarin & Tommy Ice) [Visualizer]
    "FpkBTOE_yXc", // Kam Prada - Left Me For Dead [Visualizer]
    "N5_xETV9s2E", // Kam Prada - Never Enough [Visualizer]
    "vvc11iaIyCk", // Kam Prada - Somebody New (Skit) [Visualizer]
    "l3WIUP9UoaE", // Kam Prada - Like That [Visualizer]
    "9YGcRbZk2X4", // Kam Prada - Problems [Visualizer]
    "5Rkgt9yHdpE", // Kam Prada - 3AM (Interlude) [Visualizer]
    "lNXNVmeRBHc", // Kam Prada & KAYAM - This and That [Visualizer]
    "D6Z2BhtSq0I", // Kam Prada - DND [Visualizer]
];

export const youtubeShortsIDs = [
    "quPf7lQV7A8"
];