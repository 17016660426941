import React, { useState } from "react";
import YouTube from "react-youtube";

export default function PortfolioBlockRight({ divId, videoArray, opts, text, title }) {


    //music video control
    const [videoIndex, setVideoIndex] =
        useState(0);

    const handleNext = () => {
        setVideoIndex(
            videoIndex === videoArray.length - 1
                ? 0
                : videoIndex + 1
        );
    };

    const handlePrevious = () => {
        setVideoIndex(
            videoIndex === 0
                ? videoArray.length - 1
                : videoIndex - 1
        );
    };

    return (
        <>
            <div id={divId } className="portfolio-block">
                <div className="videoText-right">
                    <h1 className="youtube-title">{title}</h1>
                    {text}
                </div>
                <div className="youtube-videos-right">
                    <YouTube
                        className="youtube-videos"
                        videoId={videoArray[videoIndex]}
                        opts={opts}
                    />
                    <div className="videoButtons-right">
                        <button
                            className="slideShowButton"
                            onClick={handlePrevious}
                        >
                            Previous
                        </button>
                        <button
                            className="slideShowButton"
                            onClick={handleNext}
                        >
                            Next
                        </button>
                    </div>
                    <br />
                </div>
            </div>
        </>
    );
}