import React from "react";
import image1 from "../../assets/story/story1.jpg";
import image2 from "../../assets/story/story2.jpg";
import image3 from "../../assets/story/story3.jpg";
import image4 from "../../assets/story/story4.jpg";
import image5 from "../../assets/story/story5.jpg";
import './Story.css';
import FadeInSection from './FadeInSection';

export default function Story() {
  return (
    <>
      <div className="pageContent">
        <FadeInSection>
          <div className="block1">
            <img
              className="blockImageL"
              src={image1}
            />
            <p className="blockContent">
              Gergo Koroknay started making videos at the age of 10, when
              he was making stop-motion videos on a first generation iPod
              Touch. Throughout high school, he was taking pictures. He
              later transitioned into videography as he started chipping
              away at obtaining his Bachelor Of Communications - Broadcast
              Media Studies degree at Mount Royal University. Since
              establishing his company, Spaced Out Films, he has touched
              countless ideas, projects and clients with his passion.
            </p>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className="block1">
            <img
              className="blockImageR"
              src={image2}
            />
            <p className="blockContent">
              Since he set foot on his first professional production set
              during the filming of Kam Pradas “Back Again” in early 2021
              as a drone operator, his skills increased with every new
              project; extending his abilities to be capable in writing,
              storyboarding, lighting, directing, drone operating, costume
              and set designing, filming, editing and animating music
              videos.
            </p>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className="block1">
            <img
              className="blockImageL"
              src={image3}
            />
            <p className="blockContent">
              Working on sets of all sizes has rendered him a strong and
              commanding director, who will make his ideas a reality every
              single time. From the freezing mountain peaks in Golden,
              British Columbia, to the scorching hot desert in Death
              Valley, California, Gergo brings the same intensity and
              ultra-effective workflow without fail.
            </p>
          </div>
        </FadeInSection >
        <FadeInSection>
          <div className="block1">
            <img
              className="blockImageR"
              src={image4}
            />
            <p className="blockContent">
              Gergo has reimagined the prospect of purchasing media
              services with his innovative new company, Spaced Out Films.
              His objective is to cut out the hassle that comes with
              hiring a media team, and instead have quick and friendly
              exchanges until shoot day.
            </p>
          </div>
        </FadeInSection>

        <FadeInSection>  <div className="block1">
          <img
            className="blockImageL"
            src={image5}
          />
          <p className="blockContent">
            Up front quotes with competitive prices, easy and efficient
            production days, coupled with unbeatable return times and a
            service that delivers an industry-standard product, makes
            Spaced Out Films your #1 choice for videography services.
          </p>
        </div></FadeInSection>
      </div >
    </>
  );
}
