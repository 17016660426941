import React from "react";
import './Saturn.css';

export default function Saturn() {
    return  <div className="saturn">
        <div className="planet"></div>
        <div className="ring">
          <span style={{ '--i': 1 }}>S</span>
          <span style={{ '--i': 2 }}>p</span>
          <span style={{ '--i': 3 }}>a</span>
          <span style={{ '--i': 4 }}>c</span>
          <span style={{ '--i': 5 }}>e</span>
          <span style={{ '--i': 6 }}>d</span>
          <span style={{ '--i': 7 }}>-</span>
          <span style={{ '--i': 8 }}>O</span>
          <span style={{ '--i': 9 }}>u</span>
          <span style={{ '--i': 10 }}>t</span>
          <span style={{ '--i': 11 }}>-</span>
          <span style={{ '--i': 12 }}>F</span>
          <span style={{ '--i': 13 }}>i</span>
          <span style={{ '--i': 14 }}>l</span>
          <span style={{ '--i': 15 }}>m</span>
          <span style={{ '--i': 16 }}>s</span>
          <span style={{ '--i': 17 }}>-</span>
          <span style={{ '--i': 18 }}>S</span>
          <span style={{ '--i': 19 }}>p</span>
          <span style={{ '--i': 20 }}>a</span>
          <span style={{ '--i': 21 }}>c</span>
          <span style={{ '--i': 22 }}>e</span>
          <span style={{ '--i': 23 }}>d</span>
          <span style={{ '--i': 24 }}>-</span>
          <span style={{ '--i': 25 }}>O</span>
          <span style={{ '--i': 26 }}>u</span>
          <span style={{ '--i': 27 }}>t</span>
          <span style={{ '--i': 28 }}>-</span>
          <span style={{ '--i': 29 }}>F</span>
          <span style={{ '--i': 30 }}>i</span>
          <span style={{ '--i': 31 }}>l</span>
          <span style={{ '--i': 32 }}>m</span>
          <span style={{ '--i': 33 }}>s</span>
          <span style={{ '--i': 34 }}>-</span>
        </div>
      </div>
}