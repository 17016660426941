import React from "react";
import image1 from "../../assets/slideshow/slide1.jpg";
import image2 from "../../assets/slideshow/slide2.jpg";
import image3 from "../../assets/slideshow/slide3.jpg";
import image4 from "../../assets/slideshow/slide4.jpg";
import image5 from "../../assets/slideshow/slide5.jpg";
import Slideshow from "./Slideshow.js";
import HomeVideos from "./HomeVideos";
import Saturn from "../saturn/Saturn.js";
import ServiceBlock from "../services_options/ServiceBlock";
import './Home.css';


export default function HomeContent() {
  const images = [image1, image2, image3, image4, image5];

  return (
    <div className="pageContent">
      <section className="homeTitleSection">
      <Saturn />
      <h1 className="homeTitle">Your one stop for Videography.</h1>
      <Saturn />
      </section>
      <div className="service-content">
        <ServiceBlock title="Weddings" imageID="service-block1" hashLink="weddings"/>
        <ServiceBlock title="Visualizers" imageID="service-block2" hashLink="visualizers" />
        <ServiceBlock title="Music Videos" imageID="service-block3" hashLink="music" />
        <ServiceBlock title="Trailers" imageID="service-block4" hashLink="trailers" />
        <ServiceBlock title="Promos" imageID="service-block5" hashLink="promos" />
        <ServiceBlock title="Live Event Recaps" imageID="service-block6" hashLink="live" />
      </div>
    </div>
  );
}
