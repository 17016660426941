import React, { useState, useEffect } from "react";
import PortfolioBlockLeft from "./PortfolioBlockLeft";
import PortfolioBlockRight from "./PortfolioBlockRight";
import PortfolioBlockCenter from "./PortfolioBlockCenter";
import { videoIdsMusicVideos, videoIdsTrailers, videoIdsLiveEvents, videoIdsPromos, videoIdsWeddings, videoIdsVisualizers, youtubeShortsIDs } from "./Constants.js";
import './Portfolio.css';
import YouTubeShortEmbed from "./PortfolioBlockCenter";


const YouTubeSlideshow = () => {
  // list of YouTube video IDs in Constants.js

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const opts = {
    height: screenSize.width > 768 ? screenSize.height / 1.5 : screenSize.height / 2.5,
    width: screenSize.width > 768 ? screenSize.width / 1.8 : screenSize.width / 1,
    playerVars: {
      autoplay: 0,
      mute: 0,
    },
  };

  const optsShorts = {
    height: 560,
    width: 320,
  }

  return (
    <>
      <PortfolioBlockLeft divId="music" videoArray={videoIdsMusicVideos} opts={opts} title="Music Videos" text=" Anything goes when you hire Spaced Out Films for your music videos.
        Specializing in real-life set design, no location is too outlandish.
        Industry standard video quality matched with a flow between clips that
        is comparable to none." />

      {screenSize.width > 768 ? <PortfolioBlockRight divId="trailers" videoArray={videoIdsTrailers} opts={opts} title="Trailers" text=" Bring on the intensity with a trailer that's bound to give the viewer
        goosebumps. Treat your movie, album or song with the preview video it
        deserves" /> : <PortfolioBlockLeft divId="trailers" videoArray={videoIdsTrailers} opts={opts} title="Trailers" text=" Bring on the intensity with a trailer that's bound to give the viewer
        goosebumps. Treat your movie, album or song with the preview video it
        deserves" />}

      <PortfolioBlockLeft divId="promos" videoArray={videoIdsPromos} opts={opts} title="Promos" text=" Promote your business, concert, live event, and anything else you can
        imagine with a compelling promotional video that will capture the
        essence of your offering, and keep the viewer hooked 100% of the time."/>

      {screenSize.width > 768 ? <PortfolioBlockRight divId="live" videoArray={videoIdsLiveEvents} opts={opts} title="Live Events" text=" In depth capturing of footage will relay to the viewer just how amazing
        your event was. From concerts, festivals, parties and more, preserve the
        excitement of your event forever."/> : <PortfolioBlockLeft divId="live" videoArray={videoIdsLiveEvents} opts={opts} title="Live Events" text=" In depth capturing of footage will relay to the viewer just how amazing
        your event was. From concerts, festivals, parties and more, preserve the
        excitement of your event forever."/> }

      <PortfolioBlockLeft divId="weddings" videoArray={videoIdsWeddings} opts={opts} title="Weddings" text=" Remember your special day with a beautiful video that will remind you of
        the magic you felt, every single time you watch it."/>

      {screenSize.width > 768 ? <PortfolioBlockRight divId="visualizers" videoArray={videoIdsVisualizers} opts={opts} title="Visualizers" text=" Couple your song or album with a smooth, yet unique display of your
        talent. Your visualizer will be the mini music video playing in your
        listeners head every time they click your song."/> : <PortfolioBlockLeft divId="visualizers" videoArray={videoIdsVisualizers} opts={opts} title="Visualizers" text=" Couple your song or album with a smooth, yet unique display of your
        talent. Your visualizer will be the mini music video playing in your
        listeners head every time they click your song."/>}
      
      <PortfolioBlockCenter divId="shorts" videoArray={youtubeShortsIDs} opts={optsShorts} title="Shorts" text="Tiktoks and Youtube Shorts."/>
    </>
  );
};

export default YouTubeSlideshow;
