import React from 'react';
import { HashLink } from 'react-router-hash-link';

function PortfolioLink({ title, hashLink, imageID }) {
    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -90; // Adjust this value to your desired offset
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    };

    return (
        <HashLink to={`/portfolio#${hashLink}`} scroll={scrollWithOffset} className="links">
            <div className="service-block" id={imageID}>
                <p className="service-block-text">{title}</p>
            </div>
        </HashLink>
    );
}

export default PortfolioLink;
