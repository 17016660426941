import React from "react";
import "./App.css";
import {Routes, Route} from "react-router-dom";
import Stars from "./components/stars/Stars";
// import Navbar from "./components/Navbar";
import ServicesOptions from "./components/services_options/Services_Options.js";
import Portfolio from "./components/portfolio/Portfolio.js";
import Story from "./components/story/Story.js";
import Contact from "./components/contact/Contact.js";
import Error from "./components/error/Error.js";
import HomeContent from "./components/home/HomeContent";
import Navbar from "./components/navbar/NavbarNew";
import Footer from "./components/footer/Footer";
import Entertainment from "./components/entertainment/Entertainment";

function App() {
  return (
    <>
      <Stars />
      <Navbar />

      <Routes>
        <Route exact path="/" element={<HomeContent />}></Route>
        {/* <Route
          exact
          path="/service&options"
          element={<ServicesOptions />}
        ></Route> */}
        <Route exact path="/entertainment" element={<Entertainment />}></Route>
        <Route exact path="/portfolio" element={<Portfolio />}></Route>
        <Route exact path="/story" element={<Story />}></Route>
        <Route exact path="/contact" element={<Contact />}></Route>
        <Route element={<Error />}></Route>
      </Routes>
      <Footer />
    </>
  );
}

export default App;
