import { NavLink } from "react-router-dom";
import React, { useState } from "react";
import spaceman from "../../assets/home/spaceman.png";
import { GiHamburgerMenu } from "react-icons/gi";
import './Navbar.css';

export default function Navbar() {
    const [showMenu, setShowMenu] = useState(false);

    const handleMenuClick = () => {
        setShowMenu(!showMenu);
    };

    const hideMenu = () => {
        setShowMenu(false);
    };

    return (
        <>
            <nav className="navbar">
                <NavLink to="/" onClick={hideMenu}>
                    <a href="/">
                        <img
                            id="nav-spaceman-photo"
                            src={spaceman}
                            alt="Spaceman"
                        />
                    </a>
                </NavLink>
                {/* Causes the button to appear  */}
                <button onClick={handleMenuClick}>
                    <GiHamburgerMenu className="hamburger" />
                </button>
                <div className={`nav-menu ${showMenu && 'active'}`}>
                    <ul>
                        <li>
                            <NavLink
                                to="/"
                                activeClassName="active"
                                onClick={hideMenu}
                            >
                                Home
                            </NavLink>
                        </li>
                        {/* <li>
                            <NavLink
                                to="/service&options"
                                activeClassName="active"
                                onClick={hideMenu}
                            >
                                Services and Options
                            </NavLink>
                        </li> */}
                        <li>
                            <NavLink
                                to="/portfolio"
                                activeClassName="active"
                                onClick={hideMenu}
                            >
                                Portfolio
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/story"
                                activeClassName="active"
                                onClick={hideMenu}
                            >
                                The Story
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/contact"
                                activeClassName="active"
                                onClick={hideMenu}
                            >
                                Contact
                            </NavLink>
                        </li>
                         {/* <li>
                            <NavLink
                                to="/entertainment"
                                activeClassName="active"
                                onClick={hideMenu}
                            >
                                Entertainment
                            </NavLink>
                        </li> */}
                    </ul>
                </div>
            </nav>
        </>
    );
}
