import React from "react";
import PortfolioLink from "./PortfolioLink";



export default function ServiceBlock({ title, imageID, hashLink }) {
    return (
        <PortfolioLink title={title} imageID={imageID} hashLink={hashLink}/>
    );
}
