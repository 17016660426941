import React from "react";
import { FaInstagram } from 'react-icons/fa';
import { IoMail } from "react-icons/io5";
import { IoLogoLinkedin } from "react-icons/io5";
import ContactUs from "./ContactUs";
import './Contact.css';

export default function Contact() {
  return (
    <>
      <div className="pageContent">
        <div className="contactPage">
          <h1 id="contact-title">Contact </h1>
          <p id="lets-start">Let’s Get Started!</p>
          {/* <ContactUs /> */}
          <div>
            <a href="https://www.instagram.com/spacedoutfilmsxx/" target="_blank" rel="noreferrer">
              <FaInstagram className="logoImages" />
            </a>
            <a href="mailto:director@spacedoutfilms.com" target="_blank" rel="noreferrer">
              <IoMail className="logoImages" />
            </a>
            <a href="https://www.linkedin.com/in/gergo-koroknay-2a87a0235/" target="_blank" rel="noreferrer">
              <IoLogoLinkedin className="logoImages" />
            </a>
          </div>

          <div className="cust-quotes">
            <p>
              “Gergo provides unmatched quality, impeccable turn around times,
              and is super easy to work with. It’s always a pleasure to work on
              different music projects with Gergo. Time and time again he is
              able to bring my creative visions to life.”
            </p>
            <p>- Kam Prada</p>
          </div>
        </div>
      </div>
    </>
  );
}
