import React from "react";
import './Entertainment.css';

export default function Story() {
  return (
    <>
      <div className="pageContent">

      </div >
    </>
  );
}
