import React from "react";
import YouTubeSlideshow from "./YoutubeVideos";


export default function Portfolio() {
  return (
    <>
      <div className="pageContent">
        <YouTubeSlideshow />
      </div>
    </>
  );
}
