import React from "react";
import "./stars.css";

export default function Stars() {
  return (
    <>
      <div className="stars"></div>
      <div className="twinkling"></div>
    </>
  );
}
